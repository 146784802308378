import {useState, useRef, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import { useApi } from "../hooks/useApi";
import {Spinner} from '../components/Spinner';
import { version } from './Login';

export type TToken = {
  id: number;
  token: string;
  expire: number;
  level: number;
}

const Config = () => {
  //https://ichi.pro/react-router-v-6-no-shin-kino-54507686506060
  const navigate = useNavigate()
  const [done, setDone] = useState(false);
  const [serviceCodeError, setServiceCodeError] = useState(false);
  const [serviceCode, setServiceCode] = useState("");
  const { running, error, data, execute } = useApi();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const current = inputRef.current;
    current && current.focus();
    const service_code = localStorage.getItem("service_code") || "";
    if (service_code) {
      setServiceCode(service_code);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (error && error?.status === 401) {
      setServiceCodeError(true);
    }
    if (data && data.code === '200') {
      localStorage.setItem('service_code',  serviceCode);
      localStorage.setItem('api_server',  data.api_server);
      localStorage.setItem('app_server',  data.app_server);
      setDone(true);
      const app_url = `${data.app_server}`
      window.location.assign(app_url);
    }
    // eslint-disable-next-line
  }, [error, data]);

  const changeServiceCode = (e:React.ChangeEvent<HTMLInputElement>) => {
    setServiceCode(e.target.value);
    setServiceCodeError(false);
  };

  const Config = () => {
    serviceCode.length === 0 && setServiceCodeError(true);

    const params = {
      service_code: serviceCode,
    };
    if (serviceCode.length ) {
      execute({ url: "/v1/config", params, redirect: false });
    }
  };

  return (
    <>
      <div className="h-screen flex flex-col py-12 bg-gray-200 ">

        <div className="bg-gray-800 top-0 w-full absolute">
          <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8 ">
            <div className="mt-8 md:mt-0 md:order-1 w-full ">
                  <div className="font-serif text-3xl font-black">
                    <span className="text-blue-900 gradient">＠Archives!</span>
                  </div>
            </div>
          </div>
        </div>

        <div className="sm:mx-auto sm:w-full sm:max-w-md mt-20 text-center">
          <div>
            <span className="mt-6 text-center text-2xl font-extrabold text-gray-900">電子帳簿保存アーカイブ</span>
            <span className="text-gra-600 text-xs ml-4">Ver.{version}</span>
          </div>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div className="space-y-6" >
              <div className="text-lg font-bold text-gray-600 border-b border-gray-200">初期設定</div>
              <div className="">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  サービスコード
                </label>
                <div className="mt-1">
                  <input
                    onChange={changeServiceCode}
                    value={serviceCode}
                    ref={inputRef}
                    id="service_code"
                    name="service_code"
                    type="text"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>

                {serviceCodeError && (
                <div className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-1 rounded relative mt-3" role="alert">
                  <span className="block sm:inline text-sm">サービスコード が入力されていません。</span>
                </div>
                )}
              </div>

              <div className="text-sm text-gray-600">
                サービスコードを入力してください。<br />
                API情報がブラウザに保存されます。<br />
                ミライネットは mirainet<br />
                アミックスコムは amixcom<br />
                と入力してください。
              </div>



              {serviceCodeError && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                  <strong className="font-bold text-sm">初期設定エラー</strong><br />
                  <span className="block sm:inline text-sm">入力されたサービスコードは登録されていません。</span>
                  <span className="absolute top-0 bottom-0 right-0 px-3 py-3"
                    onClick={() => setServiceCodeError(false)}>
                    <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
                  </span>
                </div>
              )}



              <div>
                {!running && !done ?(
                <button
                  onClick={Config}
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 "
                >
                  <strong className="font-bold text-sm">設定</strong>
                </button>
                ) : (
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-500 cursor-default"
                >
                  <strong className="font-bold text-sm">設定</strong>
                </button>
                )}
              </div>
            </div>
      {running && <Spinner text="Now Config processing..." />}

      {error && error.status !== 401 && <span className="mt-4 inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-pink-100 text-pink-800 ml-4 mb-4">
                  初期設定処理中にエラーが発生しました。<br />しばらくたってから再度お試しください。
                </span>
      }

          </div>
        </div>


        <div className="bg-gray-100 bottom-0 w-full absolute">
          <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8 ">
            <div className="mt-8 md:mt-0 md:order-1 w-full ">
              <p className=" text-center text-base text-gray-400">&copy; 2022 Mirai Communication Network, Inc. All rights reserved.</p>
            </div>
          </div>
        </div>

      </div>

    </>
  )
}

export default Config;
